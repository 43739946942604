<template>
  <div class="system-control">
<!--    选择备用泵-->
    <div class="item">
      <img class="icon" src="@/assets/system-pump.png"/>
      <RowItem class="name">选择备用泵</RowItem>
      <RowItem>
        <div class="value-row clickable" @click="clickSystemPump">
          <div class="number">{{systemControl.standbyPump.value}}</div>
          <div class="symbol">#</div>
        </div>
      </RowItem>
    </div>

    <!--    时间校准-->
    <div class="item">
      <img class="icon" src="@/assets/system-timing.png"/>
      <RowItem class="name">时间校准</RowItem>
      <RowItem>
        <div class="value-row clickable" @click="clickAdjustTime">
          <div class="number">12:34:00<br/>2013-01-02</div>
        </div>
      </RowItem>
    </div>

    <InputDialog ref="inputDialog"></InputDialog>
  </div>
</template>

<script>
import InputDialog from "@/components/dialog/InputDialog";
import RowItem from "./RowItem";

export default {
  components: {RowItem,InputDialog},
  props: ["systemControl"],
  computed: {

  },
  methods:{
    clickSystemPump(){
      let that = this
      this.$refs.inputDialog.show({
        title:"选择备用泵",
        value:this.systemControl.standbyPump.value,
        onOk(value){
          that.$emit("control",that.systemControl.standbyPump.dataId,value)
        }
      })
    },
    clickAdjustTime(){

    },
    clickLockTime(){
      this.$refs.inputDialog.show({
        title:"设备锁机计时",
        value:0,
        onOk(value){

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.system-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .item {
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;

    .icon {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }

    .name {
      text-align: center;
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }


    .value-row{
      display: flex;
      border-bottom: 1px solid #000;
      height: 70px;
      align-items: flex-end;
      font-size: 22px;
      width: 140px;
      .number{
        flex: 1;
      }
      .stop-use{
        font-size: 12px;
        line-height: 16px;
      }
      .switch-icon{
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }

  }
}
</style>
